.widget.grid {
  flex-wrap: wrap;
  align-items: flex-start;
  margin: -1em;
  .media(computer-largeScreen, {
    margin: -2em;
  });
  > .wrapper {
    flex: 0 1 100%;
    padding: 1em;
    margin: 0;
    &.three {
      .media(widescreen, {
        flex-basis: percentage(2/3)
      });
    }
    &.two {
      .media(tablet, {
        flex-basis: 100%
      });
      .media(largeScreen, {
        flex-basis: percentage(2/3);
      });
    }
    &.one {
      .media(tablet, {
        flex-basis: percentage(1/2);
      });
      .media(largeScreen, {
        flex-basis: percentage(1/3);
      });
    }
  }
}

@container dashboard (inline-size < 620px) {
  .sso-tiles-container {
    grid-template-columns: 1fr !important;
  }

  .obt-tile {
    padding: 16px !important;

    p {
      font-size: 14px;
      margin-bottom: 8px !important;
    }
  }
}

@container dashboard (inline-size >= 620px) {
  .obt-tile {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .sso-tiles-container {
    grid-auto-rows: 98px;
  }
}

.sso-tiles-container {
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(334px, 334px));
  margin-bottom: 1.5em;
  column-gap: 1.5em;
  row-gap: 1.5em;
  .media(tablet-down, {
    column-gap: 1em;
    row-gap: 1em;
    margin-bottom: 1em;
  });
  .obt-tile {
    padding: 24px;
    border-radius: 0.5em;
    background-color: #002534;
    background-size: cover;
    border: 1px solid @dark-borderColor;
    color: white;
    .chakra-ui-light & {
      background-color: white;
      color: black;
      border: none;
    }

    .obt-tile-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .obt-header {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      .obt-tile-button {
        background-color: @blueColor;
        &:hover {
          background-color: darken(@blueColor, 10%);
        }
        color: white;
        > i {
          opacity: 1;
        }
      }
    }
  }
}

.obt-modal.modal.ui.visible {
  border-radius: 16px;
  color: white;

  .chakra-ui-light & {
    color: black;
  }
  > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: @bodyBg;
    .chakra-ui-light & {
      background-color: @light-bodyBg;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin: 0;
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.announcement-banner {
  transition: width 0.5s ease-out 0.5s;
  &.condensed {
    .media(mobile, {
      width: 100%;
    });
  }
  flex-shrink: 1;
  padding: 1.5em;
  margin-bottom: 1.5em;
  .media(tablet-down, {
    margin-bottom: 1em;
  });
  background: @widgetBg;
  border: 1px solid @dark-borderColor;
  border-radius: @borderRadiusLarge;
  transition: border-color 0.3s ease, background 0.3s ease, width 0.3s ease;
  .chakra-ui-light & {
    background: @light-widgetBg;
    border-color: @light-borderColor;
  }
  .announcement-banner-message {
    position: relative;
    padding: 1em;
    width: 100%;
    background: white;
    border: 1px solid @dark-borderColor;
    border-radius: @borderRadius;
    transition: border-color 0.3s ease, background 0.3s ease,
      max-height 0.3s ease;
    color: black;
    margin-bottom: 1em;
    max-height: 100px;
    overflow: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    &.show-scrollbar {
      &::-webkit-scrollbar {
        display: initial;
      }
    }

    &.is-open {
      scrollbar-width: none;
      max-height: 1000px;
      overflow-y: auto;
    }
    > .dropdown-icon-wrapper {
      background: white;
      position: absolute;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      cursor: pointer;
      > .arrow-dropdown {
        margin: 0;
        color: var(--primaryColor);
        width: 1.25em;
        transition: transform 0.3s ease;
        &.is-open {
          transform: rotate(180deg);
        }
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .chakra-ui-light & {
      border-color: @light-borderColor;
    }
  }
}

.no-widgets {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: @widgetBg;
  .chakra-ui-light & {
    background: @light-widgetBg;
  }
  > div {
    flex: 0 1 100%;
    > button {
      background-color: #57cc71 !important;
      width: 230px !important;
      height: 50px !important;
    }
  }
  i {
    font-size: 80px;
  }
  h2 {
    margin-top: 40px;
    margin-bottom: 60px;
  }
}

/**
Display Grid Styles
**/

.toggle-aside {
  transition: all 0.8s ease-out;
  padding: 0.5em 0;
  line-height: 30px;
  float: right;
  display: block;
  text-align: center;
  display: block;
  width: 60px;
  font-size: 1em;
  cursor: pointer;
  transform: rotate(540deg);
  > i {
    margin: 0;
    color: @whiteColor;
    transition: color 0.3s ease;
    .chakra-ui-light & {
      color: inherit;
    }
  }
}
.aside-menu {
  width: 100% !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}
.draggable-widget-item {
  color: #fff;
}
.container {
  display: flex;
  color: @textColor;
  transition: color 0.3s ease;
  .chakra-ui-light & {
    color: @light-textColor;
  }
  > input[type="checkbox"] {
    display: none;
    &:checked + .aside {
      transition: flex-basis, 0.3s ease-out;
      flex: 0 0 300px;
      @media only screen and (max-width: 768px) {
        position: absolute;
        width: 300px;
        height: 100%;
      }
      .toggle-aside {
        transform: rotate(0deg);
      }
      > aside {
        .brand-logo {
          width: 300px;
        }
        .widget {
          display: block;
        }
        .item {
          color: #fff;
          width: 100%;
          height: 50px;
          &.header {
            transition: height 0.5s ease 0.5s;
            overflow: hidden;
            margin-bottom: 0;
            border-bottom: 1px solid gray;
          }
          > i {
            display: block;
          }
        }
      }
    }
    // Allows us control over grid when our sidebar is taking up some screenspace
    &:checked ~ .content {
      @media only screen and (max-width: 1068px) {
        .display {
          &.grid {
            grid-template-columns: repeat(2, 1fr) !important;
          }
        }
        [class*="grid-column-"] {
          grid-row: span 1;
          grid-column: span 2;
        }
      }
      @media only screen and (min-width: 1068px) and (max-width: 1480px) {
        .display {
          &.grid {
            grid-template-columns: repeat(2, 1fr) !important;
          }
        }
        [class*="grid-column-3"] {
          grid-row: span 1;
          grid-column: span 2;
        }
        [class*="grid-column-2"] {
          grid-row: span 1;
          grid-column: span 2;
        }
        [class*="grid-column-1"] {
          grid-row: span 1;
          grid-column: span 1;
        }
      }
    }
  }
  .aside {
    flex: 0 0 60px;
    background: var(--headerFooterColor);
    color: #fff;
    transition: flex-basis 0.3s ease-out 0.3s;
    overflow: hidden;
    z-index: 40;
    .chakra-ui-light & {
      background: @light-widgetBg;
      color: @light-textColor;
    }

    > aside {
      margin-top: 40px;
      .brand-logo {
        transition: width 0.5s ease;
        width: 0px;
        display: flex;
        justify-content: center;
        img {
          max-height: 100px;
        }
      }
      .dashboard-link {
        width: 100%;
      }
      .widget {
        display: none;
        .item {
          padding: 0 15px;
        }
      }
      .sortable-widgets {
        .item {
          display: grid;
          grid-template-columns: 60px 1fr 40px;
          grid-template-rows: 48px;
          padding: 0;
          transition: color 0.3s ease;
          .chakra-ui-light & {
            color: @light-textColor !important;
            > .toggle {
              > label:after {
                box-shadow: none;
              }
            }
          }
          > i {
            position: relative;
            font-size: 1.3em;
            align-self: center;
            justify-self: center;
          }
          > span {
            align-self: center;
            white-space: nowrap;
          }
          > div {
            align-self: center;
          }
        }
      }
      .item {
        color: #fff;
        padding: 0;
        font-weight: 600;
        transition: color 0.3s ease;
        .chakra-ui-light & {
          color: @light-textColor;
        }
        > i {
          position: absolute;
          right: 0;
          top: 0;
          padding: 14px 8px;
          box-sizing: content-box;
          font-size: 1.3em;
          cursor: pointer;
          display: none;
          &.active {
            color: @blue;
            transition: color 0.3s ease;
            .chakra-ui-light & {
              color: @blueColor;
            }
          }
        }
        > a {
          display: grid;
          grid-template-columns: 60px 1fr;
          grid-template-rows: 48px;
          color: #fff;
          white-space: nowrap;
          cursor: pointer;
          overflow: hidden;
          transition: color 0.3s ease;
          &.widget-menu-link {
            grid-template-columns: 100% 1fr;
          }
          .chakra-ui-light & {
            color: @light-textColor;
          }
          &.active,
          &:focus {
            color: var(--primaryColor);
          }
          &:hover {
            color: var(--primaryColor) !important;
          }
          > i {
            font-size: 1.3em;
            align-self: center;
            justify-self: center;
          }
          > svg {
            width: 24px;
            align-self: center;
            justify-self: center;
            margin-right: 0.25rem;
          }
          > span {
            align-self: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          > .label-wrapper {
            display: flex;
            align-content: center;
            flex-wrap: wrap;
            > .sub-label {
              font-size: 0.75em;
              font-weight: 500;
              margin-top: 3px;
            }
          }
        }
        &.header {
          text-transform: uppercase;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 10px;
          margin-left: 20px;
          margin-right: 20px;
          transition: all 0.5s ease;
          overflow: hidden;
          height: 0;
          width: 100%;
          padding: 0 15px;
          border-bottom: 1px solid #666;
          .title {
            color: white;
            .chakra-ui-light & {
              color: black;
            }
            > svg {
              justify-self: end;
              cursor: pointer;
              transition: color 0.3s ease;
            }
          }
        }
        .title {
          display: grid;
          grid-template-columns: 1fr 40px;
          grid-template-rows: 48px;
          width: 100%;
          > svg {
            font-size: 1.3em;
            align-self: center;
            justify-self: center;
            margin: 0;
          }
          > span {
            align-self: center;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      position: absolute;
      transition: width 0.3s ease-out 0.3s;
      width: 0;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
    }
  }
  .content {
    flex: 1;
    padding: 1em;

    @media only screen and (max-width: 768px) {
      [class*="grid-column-"] {
        grid-row: span 1;
        grid-column: span 3;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      .display {
        &.grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      [class*="grid-column-3"] {
        grid-row: span 1;
        grid-column: span 2;
      }
      [class*="grid-column-2"] {
        grid-row: span 1;
        grid-column: span 2;
      }
      [class*="grid-column-1"] {
        grid-row: span 1;
        grid-column: span 1;
      }
    }
  }

  @media only screen and (min-width: 2200px) {
    .display {
      &.grid {
        grid-template-columns: repeat(4, 1fr);
      }
    }
    [class*="grid-column-3"] {
      grid-row: span 1;
      grid-column: span 2;
    }
    [class*="grid-column-2"] {
      grid-row: span 1;
      grid-column: span 2;
    }
    [class*="grid-column-1"] {
      grid-row: span 1;
      grid-column: span 1;
    }
  }
}

.display {
  &.grid {
    display: grid;
    grid-gap: 1.5em;
    .media(tablet-down, {
      grid-gap: 1em;
    });
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 650px;
    .media(mobile, {
      grid-auto-rows: auto;
    });
    grid-auto-flow: row;
    min-width: 0;
    min-height: 0;
    > * {
      position: relative;
      align-self: stretch;
      justify-self: stretch;
      padding: 1.5em;
      background: @widgetBg;
      min-width: 0;
      border: 1px solid @dark-borderColor;
      border-radius: @borderRadiusLarge;
      transition: border-color 0.3s ease, background 0.3s ease;
      .chakra-ui-light & {
        background: @light-widgetBg;
        border-color: @light-borderColor;
      }
    }
  }
}

.grid-row (@i) when (@i > 0) {
  .grid-row-@{i} {
    grid-row: span @i;
  }
  .grid-row(@i - 1);
}
.grid-row(12);

.grid-column (@i) when (@i > 0) {
  .grid-column-@{i} {
    grid-column: span @i;
  }
  .grid-column(@i - 1);
}
.grid-column(12);
