.user-app-wrapper {
  border-radius: @borderRadiusLarge;
  height: 98px;
  cursor: pointer;
  background-color: @widgetBg;
  border: solid 1px @dark-borderColor;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  padding: 0 24px;
  max-width: 334px;
  display: flex;
  align-items: center;
  color: inherit;
  &:hover {
    background-color: darken(@widgetBg, 3%);
    color: white;
  }
  &:active {
    background-color: darken(@widgetBg, 6%);
    color: white;
  }
  .chakra-ui-light & {
    border-color: @light-borderColor;
    background-color: @light-widgetBg;

    &:focus {
      outline-color: #0094d8;
    }

    &:hover {
      background-color: var(--compassGray2);
      color: black;
    }
    &:active {
      background-color: #d0e2ff;
      color: black;
    }
  }
}

.user-app {
  display: flex;

  > .user-app-icon-wrapper {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    white-space: nowrap;

    .chakra-ui-light & {
      color: #0094d8;
      .secondary-svg-color {
        fill: #86d3f6;
      }
    }

    > i {
      font-size: 42px;
      margin: 0;
      transition: color 0.3s ease;
    }
  }
  .app-text {
    margin-left: 8px;
    width: 212px;

    > p {
      font-size: 18px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .description {
      font-size: 12px;
      display: -webkit-box;
      max-width: 200px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .expand-icon {
    color: #abb1bb;
  }
}

.user-app:hover {
  color: inherit;
}
