@flightCardIconColor: #adbfc7;

.smart-accordion {
  > .accordion-header-container {
    background: none;
    color: inherit;
    border: none;
    font-family: inherit;
    padding: 0;
    width: 100%;
    display: flex;
    &.centered {
      text-align: center;
    }
    cursor: pointer;
    &.disabled {
      cursor: initial;
    }
    > button {
      background: transparent;
      margin-left: 8px;
      float: right;
      padding: 0.25em !important;
      color: @flightCardIconColor;
      &:focus,
      &:hover {
        background: transparent;
        color: var(--primaryColor);
      }
      > svg {
        transition: transform 0.3s ease;
        &.closed {
          transform: rotate(180deg);
        }
      }
    }
  }
  .accordion-header {
    font-size: 16px;
    margin: 0;
    display: inline-block;
    width: 100%;
    &.centered {
      text-align: center;
    }
  }
  .accordion-content {
    min-height: 0;
    width: 100%;
    transition: height 500ms ease;
    overflow: hidden;
  }
}
