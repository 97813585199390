@type: "element";
@element: "icon";
@import (multiple) "../../semantic-ui/theme.config";
// Reference breakpoints from semantic ui default theme
// @mobileBreakpoint : 320px;
// @tabletBreakpoint : 768px;
// @computerBreakpoint : 992px;
// @largeMonitorBreakpoint : 1200px;
// @widescreenMonitorBreakpoint : 1440px;
// @largestMobileScreen : (@tabletBreakpoint - 1px);
// @largestTabletScreen : (@computerBreakpoint - 1px);
// @largestSmallMonitor : (@largeMonitorBreakpoint - 1px);
// @largestLargeMonitor : (@widescreenMonitorBreakpoint - 1px);

.media(@screen; @ruleSet) when (@screen = mobile-xs) {
  @media only screen and (max-width: @mobileBreakpoint) {
    @ruleSet();
  }
}
.media(@screen; @ruleSet) when (@screen = mobile) {
  @media only screen and (max-width: @largestMobileScreen) {
    @ruleSet();
  }
}
.media(@screen; @ruleSet) when (@screen = tablet) {
  @media only screen and (min-width: @tabletBreakpoint) {
    @ruleSet();
  }
}
.media(@screen; @ruleSet) when (@screen = tablet-down) {
  @media only screen and (max-width: @largestTabletScreen) {
    @ruleSet();
  }
}
.media(@screen; @ruleSet) when (@screen = computer) {
  @media only screen and (min-width: @computerBreakpoint) {
    @ruleSet();
  }
}
.media(@screen; @ruleSet) when (@screen = computer-down) {
  @media only screen and (max-width: @largestTabletScreen) {
    @ruleSet();
  }
}
.media(@screen; @ruleSet) when (@screen = largeScreen) {
  @media only screen and (min-width: @largeMonitorBreakpoint) {
    @ruleSet();
  }
}
.media(@screen; @ruleSet) when (@screen = largeScreen-down) {
  @media only screen and (max-width: @largestSmallMonitor) {
    @ruleSet();
  }
}
.media(@screen; @ruleSet) when (@screen = widescreen) {
  @media only screen and (min-width: @widescreenMonitorBreakpoint) {
    @ruleSet();
  }
}
.media(@screen; @ruleSet) when (@screen = widescreen-down) {
  @media only screen and (max-width: @largestLargeMonitor) {
    @ruleSet();
  }
}
.media(@screen; @ruleSet) when (@screen = tablet-computer) {
  @media only screen and (min-width: @tabletBreakpoint) and (max-width: @computerBreakpoint) {
    @ruleSet();
  }
}
.media(@screen; @ruleSet) when (@screen = computer-largeScreen) {
  @media only screen and (min-width: @computerBreakpoint) and (max-width: @largeMonitorBreakpoint) {
    @ruleSet();
  }
}

.media(@screen; @ruleSet) when (@screen = ipad-pro-portrait) {
  @media only screen and (min-height: @largeMonitorBreakpoint) and (max-width: @largeMonitorBreakpoint) {
    @ruleSet();
  }
}
