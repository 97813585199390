.simple-smart-modal {
  width: 400px;
  .content-container {
    display: flex;
    .fields-container {
      flex-grow: 1;
      a {
        cursor: pointer;
        &.disabled {
          cursor: initial;
        }
      }
      .modal-row {
        display: flex;
        width: 100%;
        margin-bottom: 16px;
        > .modal-column {
          .modal-header {
            font-size: 22px;
          }
          label {
            display: block;
            text-transform: uppercase;
            color: gray;
            font-size: 14px;
            font-weight: 600;
          }
          span {
            font-weight: 600;
          }
          width: 50%;
          display: inline-block;
        }
      }
    }
    .close-button-container {
      border-radius: 50%;
      cursor: pointer;
      min-height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 35px;
      max-height: 35px;
      > i {
        margin: 0;
      }
      &:hover {
        background: lighten(@grayColor, 20%);
      }
    }
  }
}
