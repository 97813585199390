#root {
  flex-direction: column;
  display: flex;
  min-height: 100%;
}

.light-mode {
  transition: color 0.3s ease;
  color: @light-textColor;
  a {
    transition: color 0.3s ease;
  }
}

.layout {
  background: @bodyBg;
  background-size: cover;
  transition: background 0.3s ease;

  &.impersonation {
    border: 2px solid red;
  }
  &.global-risk-map {
    height: 100vh;
  }
  .chakra-ui-light & {
    background: @light-bodyBg;
  }
  @media screen and (min-width: 1921px) {
    background-size: cover !important;
  }
  &.has-hamburger {
    overflow: hidden;
    .media(computer-down, {
      height: 100%; // remove background scrollback on mobile
    });
  }
  main {
    display: flex;
    position: relative;
    > .content {
      min-height: calc(~"100vh - 158px - 70px");
      container: dashboard / inline-size;
      background: @bodyBg;
      max-width: 1440px;
      margin: 0 auto;
      .chakra-ui-light & {
        background: @light-bodyBg;
      }
    }

    .chatbot-window {
      text-align: right;
      position: fixed;
      height: 600px;
      border-radius: @borderRadiusLarge;
      border: solid 1px @grayColor;
      bottom: 25px;
      right: 100px;
      z-index: 10000000000;
      padding: 8px;
      width: 420px;
      background: @bodyBg;
      background-size: cover;
      transition: background 0.3s ease;
      .loading-container {
        height: 90%;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: flex;
        > .loading-text {
          margin-top: 16px;
        }
      }
      .chakra-ui-light & {
        background: @light-bodyBg;
      }
      .close-chatbot-window {
        cursor: pointer;
      }
      .agent-chat-iframe {
        height: calc(~"100% - 40px");
        width: 100%;
        margin-top: 10px;
      }
    }

    .media(computer-down, {
      //margin-top: @layoutMainMenuTopPadding;
    });
    .media(mobile, {
      margin-top: @layoutMainMenuTopPaddingMobile;
    });
    > .left {
      background: rgba(0, 0, 0, 0.3);
      padding: 1em;
      text-align: right;

      .ui.segment {
        margin: 0;
        border: 0;
      }
    }

    > .right {
      width: 100%;
      padding: 1em;
      color: @white;
      .media(computer, {
        padding: 2em;
      });
    }

    .ui.button.aside.toggle {
      &.condensed {
        width: 100%;
        transition: width 0s ease 0.5s;
      }
    }
  }
}
