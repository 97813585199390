@import "../../styling/heading.less";

.flex.grid {
  display: flex;
  &.center {
    justify-content: center;
    align-items: center;
  }
  &.column {
    flex-direction: column;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.space-between {
    justify-content: space-between;
    align-items: center;
  }
  &.flex-end {
    justify-content: flex-end;
  }
  &.vert-center {
    align-items: center;  
  }
}

.flex-vert-center {
  display: flex;
  align-items: center;
  &.grow {
    height: 100%;
    width: 100%;
  }
}
