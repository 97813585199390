.flight-picker-dropdown {
  &.disabled {
    opacity: 0.65;
  }
  position: relative;
  min-height: 40px;
  background: #fff;
  border-radius: 0.25rem;
  > svg,
  i {
    position: absolute;
    font-size: 16px;
    width: 16px;
    color: #000;
    top: 13px;
  }
  .dropdown-left-icon {
    left: 7px;
  }

  .arrow {
    right: 5px;
    width: 13px;
    top: 35%;
    color: var(--primaryColor);
    transition: color 0.3s ease;
  }

  > .dropdown {
    display: flex !important;
    align-items: center !important;
    justify-content: left !important;
    border: none;
    padding-left: 20px !important;
    min-height: 40px !important;
    line-height: 40px !important;
    z-index: 1;
    background: transparent !important;
    > input.search {
      padding-left: 0.7em !important;
    }
    .delete.icon {
      position: static;
    }
    > .dropdown.icon:before {
      content: none;
    }
    &.disabled.loading.search.selection {
      > i.dropdown.icon {
        padding: 1.65em 1.115em !important;
      }
    }

    > .menu {
      z-index: 99999;
      > .item {
        min-height: 40px;
        overflow: hidden;
        &:hover,
        &.active,
        &.selected {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .text {
    font-weight: 600;
    line-height: 1em;
  }
}

.no-left-icon-dropdown {
  .text {
    float: left;
  }
  &.centered-dropdown {
    .text {
      float: none;
      margin: 0;
    }
  }
  > .dropdown {
    padding-left: 10px !important;
  }
}

.centered-dropdown {
  .dropdown {
    text-align: center;
    justify-content: center !important;
  }
  .item {
    text-align: center !important;
  }
}

.allow-wrap-dropdown {
  > .dropdown {
    flex-wrap: wrap;
  }
}
