
.ui.animated.circular.button {
  >.hidden.content {
    display: block !important;
    padding: 0;
  }
  >.visible.content {
    right: 50%;
    padding: 0;
  }
}

.ui.button {
  &:focus {
    outline: auto 5px -webkit-focus-ring-color;
  }
}

.ui.checkbox {
  padding: 3px 0;
  &:focus-within {
    outline: auto 5px -webkit-focus-ring-color;
  }
}

.ui.icon.input > svg.icon {
  cursor: default;
  position: absolute;
  line-height: 1;
  text-align: center;
  top: 0px;
  right: 0px;
  margin: 0em;
  height: 100%;
  width: 2.475em;
  opacity: 0.5;
  border-radius: 0em 0.25rem 0.25rem 0em;
  transition: opacity 0.3s ease;
}

.ui[class*="left icon"].input > svg.icon {
    right: auto;
    left: 8px;
    border-radius: 0.25rem 0em 0em 0.25rem;
}