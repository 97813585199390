.grid-row {
  padding: 0 1em;
  margin-left: -.5em;
  .grid-column {
    float:left;
    padding: .5em;
  }
  > .one-whole.grid-column {
    width: 100%;
  }
  > .one-half.grid-column {
    width: percentage(1/2);
  }
  > .one-third.grid-column {
    width: percentage(1/3);
  }
  > .two-third.grid-column {
    width: percentage(2/3);
  }
  > .three-fourth.grid-column {
    width: percentage(3/4);
  }
  > .one-fourth.grid-column {
    width: percentage(1/4);
  }
}
