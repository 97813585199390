.flight-card {
  border-width: 2px !important;
  &.lowestRisk {
    border-color: @lowestRisk !important;
  }
  &.lowRisk {
    border-color: @lowRisk !important;
  }
  &.mediumRisk {
    border-color: @mediumRisk !important;
  }
  &.highRisk {
    border-color: @highRisk !important;
  }
  &.severeRisk {
    border-color: @severeRisk !important;
  }
  > .heading {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .flight-card-top-right-corner {
      display: flex;
      align-items: center;
      margin: 0;
      flex-wrap: nowrap;
      cursor: pointer;
      img {
        width: 19px;
      }
    }
  }
  .flight-card-checkin-row {
    margin: 0;
    min-width: 1px;
    min-height: 20px;
    font-size: 12px;
    font-weight: 900;
    float: right;
  }
  .flight-card-passengers-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    color: rgb(50, 50, 50);
    height: 22px;
    > span {
      height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    > .pnr {
      min-width: 80px;
    }
  }
  .flight-card-passengers-checkin {
    color: var(--primaryColor);
    cursor: pointer;
    min-width: 60px;
  }
}
