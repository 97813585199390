.loading-container {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1;
  color: white;
  transition: color 0.3s ease;
  .chakra-ui-light & {
    color: @light-textColor;
  }
  .loading-centered-div {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    img,
    svg {
      width: 200px;
      max-height: 250px;
    }
    .loading-error-header {
      font-size: 26px;
      font-weight: bold;
      margin-top: 0px;
    }
    .loading-error-text {
      display: block;
      line-height: 1.8em;
      font-size: 16px;
    }
  }
}
