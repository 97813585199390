@import "../../styling/heading.less";
@import "../../styling/common/mediaQuery.less";

@height-condensed: 50px;
@height: 70px;

header {
  background-color: var(--headerFooterColor);
  display: grid;
  grid-gap: 0;
  grid-template-columns: 0 300px 1fr 600px;
  color: white;
  .media(largeScreen, {
    grid-template-columns: 0 300px 1fr 800px;
  });
  grid-auto-rows: @height;
  grid-auto-flow: row;
  min-width: 0;
  min-height: 0;

  &.sticky {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 45;
    &.impersonation {
      border-right: 2px solid red;
      border-left: 2px solid red;
    }
    & + main {
      margin-top: @height;
    }
  }

  > * {
    position: relative;
    align-self: center;
    justify-self: stretch;
    height: 100%;
    min-width: 0;
    min-height: 0;
    .media(tablet-down, {
      height: auto;
    });
    .ui.image {
      display: inline;
    }
    img {
      max-height: 100%;
      padding: 0.5em;
    }
  }
  .toggle-logo {
    overflow: hidden;
    .media(tablet-down, {
      align-self: center;
      justify-self: center;
    });
  }
  .right-side-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    > button {
      color: white;
      margin-left: 16px;
      > svg {
        width: 30px;
      }
    }
    ul {
      .contact {
        font-size: 0.8em;
        display: flex;
        justify-content: flex-end;

        &.small {
          font-size: 0.75em;
          line-height: 1.5em;
        }
        .ellipsis-overflow {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .contact-number {
          max-width: 200px;
          margin-left: 4px;
          color: inherit;
        }
        .contact-name {
          max-width: 120px;
          .media(largeScreen, {
            max-width: 345px;        
          });
        }
        &.reduced-width {
          .contact-name {
            max-width: 95px;
            .media(largeScreen, {
              max-width: 320px;        
            });
          }
        }
      }
    }
  }
  .profile {
    cursor: pointer;
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-self: end;
    justify-content: center;
    padding: 0.5em;
    margin-left: 16px;
    img {
      padding: 0;
      max-width: none;
    }
    .avatar {
      border: 1px solid white;
      border-radius: 50%;
      padding: 8px;
      width: 40px;
      height: 40px;
      display: inline-flex !important;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      font-size: 18px;
      background-color: rgba(255, 255, 255, 0.05);
      text-transform: uppercase;
    }
    #user-profile {
      > span {
        display: none;
      }
    }
    .media(tablet, {
      #user-profile {
        > span {
          display: inline-block;
        }
      }
    });
  }
  .ctm-logo {
    img {
      max-height: 70px;
    }
    text-align: center;
    .media(tablet-down, {
      text-align: left;
    });
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .brand-logo {
    justify-self: start;
    text-align: left;
    height: 100%;
    margin-left: 8px;
  }
  .media(tablet-down, {
    grid-template-columns: 60px 220px 0 1fr;
  });
  .media(mobile, {
    grid-template-columns: 60px 0 0 1fr;
  });
}

.profile-menu-left-icon {
  margin-right: 8px;
}

.profile-menu-list {
  list-style: none;
  font-family: Inter, sans-serif;
  li {
    list-style-type: none;
    &:first-of-type {
      .profile-menu-button {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }
    &:last-of-type {
      .profile-menu-button {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
  .profile-menu-button {
    width: 100%;
    padding: 10px 16px;
    color: inherit;
    display: inline-block;
    &:hover {
      background-color: #e5effc;
    }
    &.checked {
      font-weight: bold;
    }
  }
  .impersonate-error {
    margin: 0;
    margin-top: 8px;
    font-size: 14px;
  }
}
