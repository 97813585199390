@import "../../styling/heading.less";

.ctm-icon {
  .ctm-default-text-color {
    fill: @textColor;
    .chakra-ui-light & {
      fill: @light-textColor;
    }
  }
  .ctm-primary-color {
    fill: var(--primaryColor);
  }
  .no-fill {
    fill: none;
  }

  .secondary-svg-color {
    fill: #96a1a9;
  }
}
