.widget {
  overflow: hidden;
  > h2 {
    text-transform: uppercase;
  }
  > .widget-content {
    overflow-x: hidden;
    height: 540px;
    margin-bottom: 1rem;
    &.no-overflow {
      overflow-y: hidden;
    }
    .media(mobile, {
      height: auto;
      min-height: 300px;
      > .fare-forecaster {
        max-height: 700px;
      }
      > .flight-summary {
        max-height: 400px;
      }
      > .traveler-tracker {
        height: 440px;
      }
    });
  }
}
