.layout main > .left {
  flex: 1 0 300px;
  overflow: hidden;
  z-index: 9999;
  &.floating {
    width: 300px;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .media(computer-down, {
    position: fixed;
    top: @layoutMainMenuTopPadding;
    bottom: 0;
    overflow: auto;
    background: rgba(0,0,0,0.9);
    width: 320px;
    left: -320px;
  });
  .media(mobile, {
    top: @layoutMainMenuTopPaddingMobile;
  });
  .ui.menu {
    background: transparent;
    border: 0;
    box-shadow: none;
    border-bottom: 2px solid @sideMenuBorderColor;
    border-radius: 0;
    margin: 0 0 15px;
    padding-bottom: 15px;
    text-align: left;
    width: 100%;

    &:last-child {
      border-bottom: 0;
    }
  }

  .ui.menu.vertical {
    .item {
      color: @white;
      letter-spacing: 0.025em;
      padding: 1.1em 0.6em;
      white-space: nowrap;
      overflow: hidden;

      &.header {
        text-transform: uppercase;
        letter-spacing: 0.05em;
        padding: 0;
        .icon {
          position: absolute;
          right: 10px;
          transition: opacity 0.5s ease-in;
        }
        > .title {
          overflow: hidden;
          height: 52px;
          padding: 1.1em 0.6em;
          color: @grayColor;
          &.condensed {
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
          }
          .icon {
            color: @blueColor;
          }
        }
      }

      .icon {
        font-size: 1.3em;
        margin-right: 0;
        margin-top: -2px;

        &.drag-icon {
          font-size: 1em;
          margin-right: 5px;
          cursor: pointer;
        }
      }

      > a {
        color: @white;
        white-space: nowrap;
        cursor: pointer;
        &:hover,
        // &.active,
        &:focus {
          color: @blue;
        }
        &:not(.ui) {
          line-height: 1;
        }
        > i.icon {
          margin-right: 0.7em;
          float: none;
          vertical-align: middle;
        }
      }

      .toggle {
        position: absolute;
        right: 8px;
        top: 13px;
        cursor: pointer;
      }

      .display {
        position: absolute;
        right: 10px;
        top: 19px;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.5s ease 0.8s;
        &.active {
          color: @blue;
        }
      }
    }
  }

  &.condensed {
    flex-basis: 77px;
    &.floating {
      width: 77px;
    }
    .ui.button.toggle {
      .icon {
        transform: rotate(180deg);
      }
    }
    .widget {
      display: none;
    }
    .ui.menu.vertical {
      > .item {
        &.header {
          .icon {
            opacity: 0;
            transition-duration: 0s;
            transition-delay: 0s;
          }
        }
        .display {
          opacity: 0 !important;
          transition-delay: 0s;
          transition-duration: 0s;
        }
      }
    }
  }
  &.hamburger {
    left: 0;
  }

  .ui.button.toggle {
    border: 0;
    margin: 0;
    padding: 5px 13px;
    color: @white !important;
    box-shadow: none;
    .media(computer-down, {
      display: none
    });
    > .icon {
      margin: 0 !important;
      opacity: 1;
    }
    &:hover {
      background: transparent !important;
      color: @blue !important;
      box-shadow: none;
    }
    &:active,
    &:focus {
      background: transparent !important;
      box-shadow: none;
    }
  }
}

.draggable-widget-item {
  .toggle {
    position: absolute;
    right: 8px;
  }
}
