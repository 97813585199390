@import "../../styling/heading.less";

.footer-main.ui.segment {
  background-color: #fff;
  border-radius: 0;
  border: none;
  border-top: 8px solid var(--headerFooterColor);
  margin: 0;
  display: flex;
  justify-content: center;
  padding: 16px;
  flex-wrap: wrap;
  

  .footer-container {
    max-width: 1600px;
    text-align: center;
  }
  .top-section {
    display: flex;
    justify-content: space-between;
    .media(tablet-down, {
        flex-wrap: wrap;
      });
  }
  .footer-segment {
    max-width: 530px;
    margin-right: 80px;
    margin-top: 16px;
    text-align: left;
    #contacts-list {
      font-weight: 900;
      margin: 0;
      margin-bottom: 8px;
      font-size: 18px;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  
  .ctm-links {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 6px 0;
      > a {
        color: @blueColor;
      }
    }
    .link-name {
      font-weight: 600;
    }
  }
  .copyright {
    font-size: 14px;
    margin-top: 32px;
  }
}

.layout.travelertracker {
  .footer-main.ui.segment {
    display: none;
  }
}
