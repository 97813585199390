.simple-message-container {
  background: @bodyBg;
  &.light-mode {
    background: @light-bodyBg;
    > .message-container {
      background: @light-widgetBg;
      border-color: @light-borderColor;
      color: @light-textColor;
    }
  }
  background-size: cover;
  @media screen and (min-width: 1921px) {
    background-size: cover !important;
  }
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .message-container {
    background: @widgetBg;
    border-radius: 5px;
    color: white;
    padding: 16px;
    text-align: center;
    border: 1px solid @dark-borderColor;
    > img {
      margin: 0 auto 0.75em auto;
    }
    a {
      cursor: pointer;
    }
  }
}
