.ui.grid {
  > .row {
    > .one-whole.column {
      width: 100%;
    }
    > .one-half.column {
      width: percentage(1/2);
    }
    > .one-third.column {
      width: percentage(1/3);
    }
    > .two-third.column {
      width: percentage(2/3);
    }
  }
}
