@type: "element";
@element: "icon";
@import (multiple) "../semantic-ui/theme.config";

* {
  -webkit-font-smoothing: @fontSmoothing;
  -moz-osx-font-smoothing: grayscale;
}

// custom site variables
@headerBorderColor: rgba(150, 155, 165, 0.45);
@sideMenuBorderColor: #211f26;
@footerLinkColor: #00010a;

@layoutMainMenuTopPadding: 82px;
@layoutMainMenuTopPaddingMobile: 0;

@widgetBg: rgba(30, 36, 54, 0.65);

@borderRadius: 0.25em;
@borderRadiusLarge: 0.5em;

/*
  CTM Branding
  Primary Palette
*/
@blueColor: #007db8;
@greenColor: #51ce6c;
@neutralColor: #2c3e50;
@silverColor: #f3f6fc;
@whiteColor: #ffffff;

/*
  Secondary Palette
*/
@pinkColor: #ff2d55;
@redColor: #ff0035;
@purpleColor: #6554c0;
@orangeColor: #ff9500;
@lightBlueColor: #02c2eb;
@darkGreenColor: #0c7404;
@magentaColor: #d62472;
@grayColor: #b2bac5;

//DARK THEME
@bodyBg: #002533;
@widgetBg: #003043;
@opaqueBg: #2b2a3f;
@textColor: #fff;
@dark-borderColor: #007db8;

// Light Theme
@light-widgetBg: #ffffff;
@light-bodyBg: #f4f5f7;
@light-textColor: #2c3e50;
@light-borderColor: #ebecf0;
@light-borderColorSecondary: #d3d8e3;

// World map variables
@countryBorderColor: rgb(47, 47, 68);
@countryBackgroundColor: rgb(109, 110, 129);

// Traveler Tracker Palette
@TTLavenderColor: #7b8de3;
@TTPurpleColor: #a48ad3;
@TTAquaColor: #78d7eb;
@TTYellowColor: #e8d844;

@riskOpacity: 0.4;
@redColorTransparent: fade(@redColor, @riskOpacity);
@TTLavenderColorTransparent: fade(@TTLavenderColor, @riskOpacity);
@TTPurpleColorTransparent: fade(@TTPurpleColor, 0.4);
@pinkColorTransparent: fade(@pinkColor, 0.4);
@orangeColorTransparent: fade(@orangeColor, 0.4);
@greenColorTransparent: fade(@greenColor, 0.4);
@lightBlueColorTransparent: fade(@lightBlueColor, 0.4);
@blueColorTransparent: fade(@blueColor, 0.4);
@TTAquaColorTransparent: fade(@TTAquaColor, 0.4);
@TTYellowColorTransparent: fade(@TTYellowColor, 0.4);
@TTGrayColorTransparent: fade(@grayColor, 0.4);

/*
  Risk Palette
*/
@unknownRisk: #8d8fa1;
@lowestRisk: #dedddb;
@lowRisk: #dacca1;
@mediumRisk: #fbb929;
@highRisk: #ff8400;
@severeRisk: #ff0035;

@lowestRiskOpaque: fade(@lowestRisk, @riskOpacity);
@lowRiskOpaque: fade(@lowRisk, @riskOpacity);
@mediumRiskOpaque: fade(@mediumRisk, @riskOpacity);
@highRiskOpaque: fade(@highRisk, @riskOpacity);
@severeRiskOpaque: fade(@severeRisk, @riskOpacity);

// Compass Colors
@subtleGray: #6d7880;
@defaultGray: #2d3840;
