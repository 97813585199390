html {
  overflow-y: overlay !important;
}

.day-picker {
  .DateRangePickerInput_clearDates,
  .DateRangePickerInput_calendarIcon {
    margin: 0 5px 0 8px;
  }
  .SingleDatePickerInput_calendarIcon_svg {
    fill: #000;
  }
  .SingleDatePicker {
    width: 100%;
    .SingleDatePickerInput__disabled {
      background-color: rgba(255, 255, 255, 0.65);
      .chakra-ui-light & {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
  .SingleDatePickerInput {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
    height: 40px;
    width: 100%;

    .SingleDatePickerInput_clearDate {
      padding: 4px;
      margin: 4px;
      top: 14px;
      &:hover {
        background: transparent;
      }
    }

    .DateInput {
      background: none;
      height: 100%;
      width: calc(~"100% - 30px");
      text-align: center;
    }

    .DateInput_input {
      margin: 0;
      padding: 0;
      border: none !important;
      background: none;
      height: 100%;
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-family: "Inter", "Open Sans", "Helvetica Neue", Arial, Helvetica,
        sans-serif;
    }

    .SingleDatePickerInput_calendarIcon {
      padding: 0;
    }
  }
  .DateInput_fang {
    display: none !important;
  }
}
.SingleDatePicker_picker {
  border-radius: 4px;
  z-index: 99999;
  .SingleDatePicker {
    width: 100% !important;
  }

  .DayPickerNavigation_button {
    background: none;
    border: none;
    z-index: 2;
    padding: 6px 0px;
    &:first-of-type {
      left: 55px;
    }
    &:last-of-type {
      right: 55px;
    }
  }

  .caret {
    font-size: 22px;
    color: var(--primaryColor);
    width: 15px;
    margin: 0 5px;
  }

  .CalendarDay {
    border: none;
    border-radius: 50%;
  }

  .CalendarDay__selected {
    background: var(--primaryColor);
  }

  .CalendarMonth_table {
    margin-top: 10px;
  }

  .CalendarMonth_caption {
    color: var(--primaryColor);
  }
  .CalendarMonthGrid {
    .monthContainer {
      position: relative;
      .angle {
        cursor: pointer;
        transition: none;
        position: absolute;
      }
    }
    .prevYear {
      left: 10px;
    }
    .nextYear {
      right: 8px;
    }
    div:nth-child(3) {
      .prevYear {
        display: none;
      }
    }
    div:nth-child(2) {
      .nextYear {
        display: none;
      }
    }
  }
}
