.ui.labeled.icon.button {
  &.multiselection {
    padding-left: 2em !important;
    > .text {
      line-height: 1.6em;
      font-size: 0.8rem;
    }
    > .label {
      vertical-align: middle;
      margin: 0 2px !important;
      color: #000;
      font-size: 0.8rem;
      &.active {
        background-color: #e8e8e8;
      }
    }
    > .icon {
      right: 0em;
      left: auto;
      background-color: #fff;
      cursor: pointer;
      transition: color 0.3s ease;
      color: var(--primaryColor);
    }
    &:before {
      font-family: "Icons";
      content: "\f072";
      width: 2.375em;
      position: absolute;
      left: 0;
      padding: 11px;
      top: 50%;
      line-height: 1;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      line-height: 1;
      text-align: center;
      font-size: 13px;
      color: #585a6a;
    }
    &:hover {
      background-color: #fff;
    }
  }
}
