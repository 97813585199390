@import "../../styling/heading.less";
.bg {
  &.delayed,
  &.War {
    background-color: @pinkColor;
  }
  &.on-time,
  &.Environment {
    background-color: @greenColor;
  }
  &.en-route,
  &.Protests {
    background-color: @TTPurpleColor;
  }
  &.landed {
    background-color: @TTLavenderColor;
  }
  &.not-departed,
  &.Travel {
    background-color: @orangeColor;
  }
  &.at-risk,
  &.All {
    background-color: @redColor;
  }
  &.all {
    background-color: @lightBlueColor;
    .chakra-ui-light & {
      background-color: @blueColor;
    }
  }
  &.Unknown {
    background-color: @lightBlueColor;
  }
  &.Political {
    background-color: @grayColor;
  }
  &.Crime {
    background-color: @TTYellowColor;
  }
  &.Health {
    background-color: @blueColor;
  }
  &.lowest-risk {
    background-color: @lowestRisk;
  }
  &.low-risk {
    background-color: @lowRisk;
  }
  &.medium-risk {
    background-color: @mediumRisk;
  }
  &.high-risk {
    background-color: @highRisk;
  }
  &.severe-risk {
    background-color: @severeRisk;
  }
}
.bg-opaque {
  &.delayed,
  &.War {
    background-color: @pinkColorTransparent;
  }
  &.on-time,
  &.Environment {
    background-color: @greenColorTransparent;
  }
  &.en-route,
  &.Protests {
    background-color: @TTPurpleColorTransparent;
  }
  &.landed {
    background-color: @TTLavenderColorTransparent;
  }
  &.not-departed,
  &.Travel {
    background-color: @orangeColorTransparent;
  }
  &.at-risk,
  &.All {
    background-color: @redColorTransparent;
  }
  &.all {
    background-color: @lightBlueColorTransparent;
    .chakra-ui-light & {
      background-color: @blueColorTransparent;
    }
  }
  &.Unknown {
    background-color: @lightBlueColorTransparent;
  }
  &.Political {
    background-color: @TTGrayColorTransparent;
  }
  &.Crime {
    background-color: @TTYellowColorTransparent;
  }
  &.Health {
    background-color: @blueColorTransparent;
  }
  &.lowest-risk {
    background-color: @lowestRiskOpaque;
  }
  &.low-risk {
    background-color: @lowRiskOpaque;
  }
  &.medium-risk {
    background-color: @mediumRiskOpaque;
  }
  &.high-risk {
    background-color: @highRiskOpaque;
  }
  &.severe-risk {
    background-color: @severeRiskOpaque;
  }
}
.border-color {
  &.with-psuedo {
    &.delayed,
    &.War {
      &:before,
      &:after {
        border-color: @pinkColor;
      }
    }
    &.on-time,
    &.Environment {
      &:before,
      &:after {
        border-color: @greenColor;
      }
    }
    &.en-route,
    &.Protests {
      &:before,
      &:after {
        border-color: @TTPurpleColor;
      }
    }
    &.landed {
      &:before,
      &:after {
        border-color: @TTLavenderColor;
      }
    }
    &.not-departed,
    &.Travel {
      &:before,
      &:after {
        border-color: @orangeColor;
      }
    }
    &.at-risk,
    &.All {
      &:before,
      &:after {
        border-color: @redColor;
      }
    }
    &.all {
      &:before,
      &:after {
        border-color: @lightBlueColor;
        .chakra-ui-light & {
          border-color: @blueColor;
        }
      }
    }
    &.Unknown {
      &:before,
      &:after {
        border-color: @lightBlueColor;
      }
    }
    &.Political {
      &:before,
      &:after {
        border-color: @grayColor;
      }
    }
    &.Crime {
      &:before,
      &:after {
        border-color: @TTYellowColor;
      }
    }
    &.Health {
      &:before,
      &:after {
        border-color: @blueColor;
      }
    }
    &.lowest-risk {
      &:before,
      &:after {
        border-color: @lowestRisk;
      }
    }
    &.low-risk {
      &:before,
      &:after {
        border-color: @lowRisk;
      }
    }
    &.medium-risk {
      &:before,
      &:after {
        border-color: @mediumRisk;
      }
    }
    &.high-risk {
      &:before,
      &:after {
        border-color: @highRisk;
      }
    }
    &.severe-risk {
      &:before,
      &:after {
        border-color: @severeRisk;
      }
    }
  }
  &.delayed,
  &.War {
    border-color: @pinkColor;
  }
  &.on-time,
  &.Environment {
    border-color: @greenColor;
  }
  &.en-route,
  &.Protests {
    border-color: @TTPurpleColor;
  }
  &.landed {
    border-color: @TTLavenderColor;
  }
  &.not-departed,
  &.Travel {
    border-color: @orangeColor;
  }
  &.at-risk,
  &.All {
    border-color: @redColor;
  }
  &.all {
    border-color: @lightBlueColor;
    .chakra-ui-light & {
      border-color: @blueColor;
    }
  }
  &.Unknown {
    border-color: @lightBlueColor;
  }
  &.Political {
    border-color: @grayColor;
  }
  &.Crime {
    border-color: @TTYellowColor;
  }
  &.Health {
    border-color: @blueColor;
  }
  &.lowest-risk {
    border-color: @lowestRisk;
  }
  &.low-risk {
    border-color: @lowRisk;
  }
  &.medium-risk {
    border-color: @mediumRisk;
  }
  &.high-risk {
    border-color: @highRisk;
  }
  &.severe-risk {
    border-color: @severeRisk;
  }
}
.status,
.risk-category {
  font-weight: bold;
  &.delayed,
  &.War {
    color: @pinkColor;
  }
  &.on-time,
  &.Environment {
    color: @greenColor;
  }
  &.en-route,
  &.Protests {
    color: @TTPurpleColor;
  }
  &.landed {
    color: @TTLavenderColor;
  }
  &.at-risk,
  &.All {
    color: @redColor;
  }
  &.not-departed,
  &.Travel {
    color: @orangeColor;
  }
  &.all {
    color: @lightBlueColor;
    .chakra-ui-light & {
      color: @blueColor;
    }
  }
  &.Unknown {
    color: @lightBlueColor;
  }
  &.Political {
    color: @grayColor;
  }
  &.Crime {
    color: @TTYellowColor;
  }
  &.Health {
    color: @blueColor;
  }
}

.risk-text {
  &.trivial {
    color: @lowestRisk;
  }
  &.low {
    color: @lowRisk;
  }
  &.medium {
    color: @mediumRisk;
  }
  &.moderate {
    color: @mediumRisk;
  }
  &.high {
    color: @highRisk;
  }
  &.extreme {
    color: @severeRisk;
  }
}
