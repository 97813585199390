:root {
  --primaryColor: #02c2eb;
  --primaryCompassColor: #156AF4;
  --headerFooterColor: #003043;
  --defaultGray: #2d3840;
  --subtleGray: #6d7880;
  --compassBlue1: #156AF4;
  --compassBlue2: #024EEB;
  --compassBlue3: #003043;
  --compassBlue4: #E2F5FF;
  --compassGray1: #CED3DA;
  --compassGray2: #E5EFFC;
  --compassGray3: #2D3840;
  --compassRed1: #FFD7D9;
  --compassRed2: #BD171E;
  --compassGreen1: #D9F7BE;
  --compassGreen2: #3DB10D;
}
