.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
  background-color: var(--primaryColor);
}

a {
  color: var(--primaryColor);
}

.unstyled-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  &.button-link {
    color: var(--primaryColor);
  }
  &:disabled {
    opacity: 0.5;
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.ui.dimmer .ui.workaround.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer .ui.workaround.loader:after {
  border-color: #767676 transparent transparent;
}

#scoutChatbot > :last-child {
  z-index: 1;
}

h2 {
  font-size: 1.28571429rem;
}
