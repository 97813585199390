.search-input {
  input {
    border-radius: 4px !important;
    font-weight: 600;
    padding: 0.9em !important;
  }

  > .results {
    max-height: 300px;
    overflow-y: scroll;
    z-index: 99999;
    > .result {
      .search-result {
        p {
          margin: 4px 0;
        }
        .search-result-label {
          font-size: 14px;
          font-weight: 600;
        }
        .search-result-secondary-label {
          font-size: 10px;
          color: gray;
        }
      }
      &.active {
        background: rgb(235, 235, 235) !important;
      }
    }
    > .result:hover {
      background: rgb(235, 235, 235) !important;
    }
  }
}
