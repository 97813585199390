.smart-table.ui.table.very.basic.celled {
  margin-top: 12px;
  background: transparent;
  border: none;
  flex: 1 1 auto;
  &.padded {
    tr {
      > td,
      > th {
        &:first-child {
          padding-left: 12px;
        }
        &:last-child {
          padding-right: 12px;
        }
      }
    }
  }
  thead tr:first-child > th {
    border-radius: 0;
  }
  tr {
    > td,
    > th {
      color: @whiteColor;
      .chakra-ui-light & {
        color: @light-textColor;
      }
      border: none !important;
      background: transparent;
      padding: 0.3em 0.5em;
      height: 35px;
      font-size: 12px;
      .media(largeScreen, {
       font-size: 14px;
      });
      cursor: pointer;

      &.no-pointer-cell {
        cursor: initial;
      }
    }

    > td {
      border-top: 1px solid gray !important;
    }
    .bottom-row {
      padding: 8px 0 0;
    }
    .selected-category-icon {
      margin-left: 4px;
      color: var(--primaryColor);
    }
    .data-row {
      border-radius: 0 !important;
    }
  }
  thead > tr > td {
    border-top: none !important;
  }
  &.light-mode {
    tr {
      > td,
      > th {
        color: @light-textColor;
      }
    }
  }
  &.selectable {
    > tbody {
      > tr {
        &:hover {
          // Despite all of these super specific rules, semantic's class uses !important so here we are
          background: rgba(0, 0, 0, 0.125) !important;
          .chakra-ui-light & {
            background: rgba(0, 0, 0, 0.05) !important;
          }
        }
      }
    }
  }

  tfoot {
    tr {
      th {
        border-bottom: none;
      }
    }
  }

  .footer-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: initial;
    &.float-right {
      justify-content: flex-end;
    }
    .total-amount {
      margin-right: 32px;
      .media(mobile, {
        margin-right: 8px;
        flex-shrink: 10;
       });
    }
    .pagination-container {
      display: flex;
      align-items: center;
      .pagination-text {
        min-width: 30px;
        margin-right: 16px;
        .media(mobile, {
          margin-right: 8px;
         });
      }
    }
  }
  .ui.menu {
    background: transparent;
    border-color: gray;
    .selected-page {
      font-weight: 900;
    }
    .disabled-icon {
      color: gray;
    }
    > button {
      color: @whiteColor;
      .chakra-ui-light & {
        color: @light-textColor;
      }
      border-left: 1px solid gray !important;
      &:first-of-type {
        border-left: 0 !important;
      }
    }
  }
}
